window.addEventListener('DOMContentLoaded', () => {
    const salonbaseDiv = document.getElementsByClassName('salonbase-widget')[0]
    const teamId = salonbaseDiv.dataset.team


    const widgetButtonCssString = 'box-sizing: content-box !important; position: fixed !important; z-index: 2147480999 !important; transition: transform 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0.2s ease 0s, box-shadow 0.2s linear 0s !important; margin: 0px !important; padding: 0px !important; border: 0px !important; max-height: 45px !important; min-height: 45px !important; height: 45px !important; width: 218px !important; max-width: calc(100% - 44px) !important; border-radius: 6px !important; right: 22px !important; left: auto !important; bottom: 22px !important; box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 15px !important;'


    function getWidgetCssString() {
        const widgetCssStringDesktop = 'position: fixed !important; right: 15px !important; bottom: 15px !important; max-width: calc(100% - 44px) !important;max-height: calc(100vh - 44px) !important;z-index: 2147482000 !important;transition: transform 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0.2s ease 0s !important;margin: 0px !important;height: 793px !important;width: 488px !important;border-radius: 10px !important;box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px !important; background-color: white !important;padding: 0px !important;border: 0px !important;'

        const widgetCssString = 'position: fixed !important; right: 0px !important; bottom: 0px !important; width: 100%; height: 100%; !important;z-index: 2147482000 !important; transition: transform 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0s, opacity 0.2s ease 0s !important;margin: 0px !important;border-radius: 10px !important;box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px !important; background-color: white !important;padding: 0px !important;border: 0px !important;'

        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth

        if (width < 1000) {
            return widgetCssString
        } else {
            return widgetCssStringDesktop
        }
    }

    function createWidget() {
        const iframe = document.createElement('iframe')
        iframe.src = `https://app.salonbase.nl/widget/${teamId}/book`
        iframe.title = 'Widget waarin klanten een afspraak kunnen maken'

        iframe.style.cssText = getWidgetCssString()

        return iframe
    }

    function createWidgetButton() {
        const iframe = document.createElement('iframe')
        iframe.src = `https://app.salonbase.nl/widget/${teamId}/button`
        iframe.title = 'Knop die de "Afspraak maken" widget opent'

        iframe.style.cssText = widgetButtonCssString

        return iframe
    }


    let widget = createWidget()
    widget.style.cssText = getWidgetCssString() + 'display: none;'
    document.body.append(widget)

    let widgetButton = createWidgetButton()
    document.body.append(widgetButton)

    window.onmessage = function (e) {
        switch (e.data.action) {
        case 'close':
            console.log('[salonbase]: Closing widget')
            widget.style.cssText = getWidgetCssString() + 'display: none;'
            widgetButton.style.cssText = widgetButtonCssString
            break

        case 'open':
            console.log('[salonbase]: Opening widget')
            widget.style.cssText = getWidgetCssString() + 'display: block;'
            widgetButton.style.cssText = widgetButtonCssString + 'display: none;'

            break

        default:
            console.log('[salonbase]: Not sure what do do with this action! (action: ' + e.data.action + ')')
        }
    }
})

